// API ROUTES
// Toujours déclarer les routes api ici, pour eviter des erreurs de typos dans les requetes axios
export const API_ROUTES_BASES = {
  cards: 'api/cards',
  cardEditions: 'api/card-editions',
  cardProducts: 'api/card-products',
  cardProperties: 'api/card-properties',
  cardRarities: 'api/card-rarities',
  cardTypes: 'api/card-types',
  cashboxes: 'api/cashbox-controls',
  collaborators: 'api/collaborators',
  collection: 'api/product-collections',
  collectionCategories: 'api/product-collection-categories',
  collectionEntries: 'api/product-collection-entries',
  countries: 'api/countries',
  coupons: 'api/coupons',
  customers: 'api/customers',
  emailTypes: 'api/email-types',
  exports: 'exports',
  fidelityPoints: 'api/fidelity-points',
  orders: 'api/orders',
  orderCoupons: 'api/order-coupons',
  orderPayments: 'api/order-payments',
  orderPrints: 'api/order-prints',
  orderEntries: 'api/order-entries',
  stores: 'api/stores',
  sealedProducts: 'api/sealed-products',
  authenticate: 'api/authenticate',
  stocks: 'api/stocks',
  restockPrints: 'api/restock-prints',
  retailers: 'api/retailers',
  sendEmail: 'api/send-email',
  stocksEntries: 'api/stock-entries',
  storePlaceOrders: '/api/store-place-orders',
  paymentMode: 'api/payment-modes',
  pricingTags: 'api/pricing-tags',
  printExclusions: 'api/print-exclusions',
  transfers: 'api/stock-transfers',
  productStats: 'api/product-stats',
  productStores: 'api/product-stores',
  taxes: 'api/taxes',
  langs: 'api/langs',
  litigations: 'api/litigations',
  litigationCategories: 'api/litigation-categories',
  logEntries: 'api/log-entries',
  giftCard: 'api/gift-cards',
  ranges: '/api/families', // TODO: RELANCER MAXIME SUR LE CHANGEMENT DES NOM DE ROUTES
  shippingModes: '/api/shipping-modes',
  vouchers: '/api/vouchers',
  voucherCategories: '/api/voucher-categories',
  commercialOffers: '/api/commercial-offers',
  postalRelays: 'api/postal-relays/with-external-id',
  paybacks: 'api/paybacks-report',
  productCreationRequests: 'api/product-creation-requests',
  storeHours: 'api/store-hours',
  storeExceptionalHours: 'api/store-exceptional-hours',
  storeEvents: 'api/store-events',
  storeEventCustomers: 'api/store-event-customers',
  storeEventOrders: 'api/store-event-orders',
  storeEventRanges: 'api/store-event-ranges',
  storeEventFormats: 'api/store-event-formats',
  shippingLabelsLog: '/api/shipping-label-logs',
  storeZones: 'api/store-zones',
  roles: '/api/collaborator-roles',
  roleActions: '/api/collaborator-role-actions',
  productCategories: '/api/product-categories',

  // Rework following
  inventories: '/product-inventories',
  serviceNote: '/service-notes'
}

export const API_ROUTES = {
  authenticate: {
    root: `${API_ROUTES_BASES.authenticate}`
  },
  cards: {
    root: `${API_ROUTES_BASES.cards}`
  },
  cardEditions: {
    root: `${API_ROUTES_BASES.cardEditions}`
  },
  cardProducts: {
    root: `${API_ROUTES_BASES.cardProducts}`
  },
  cardRarities: {
    root: `${API_ROUTES_BASES.cardRarities}`
  },
  cardTypes: {
    root: `${API_ROUTES_BASES.cardTypes}`
  },
  cashbox: {
    root: `${API_ROUTES_BASES.cashboxes}`,
    close: `${API_ROUTES_BASES.cashboxes}/close`,
    fund: `${API_ROUTES_BASES.cashboxes}/fund`,
    validate: `${API_ROUTES_BASES.cashboxes}/validate`
  },

  collaborators: {
    root: `${API_ROUTES_BASES.collaborators}`,
    reset: `${API_ROUTES_BASES.collaborators}/reset`,
    deactivate: `${API_ROUTES_BASES.collaborators}/deactivate`,
    activate: `${API_ROUTES_BASES.collaborators}/activate`,
    updatePassword: `${API_ROUTES_BASES.collaborators}/update-password/`
  },
  collections: {
    root: `${API_ROUTES_BASES.collection}`
  },
  collectionCategories: {
    root: `${API_ROUTES_BASES.collectionCategories}`
  },
  collectionEntries: {
    root: `${API_ROUTES_BASES.collectionEntries}`
  },
  countries: {
    root: `${API_ROUTES_BASES.countries}`
  },
  coupons: {
    root: `${API_ROUTES_BASES.coupons}`
  },
  customers: {
    root: `${API_ROUTES_BASES.customers}`,
    reset: `${API_ROUTES_BASES.customers}/reset`,
    toPro: `${API_ROUTES_BASES.customers}/transform-to-pro`,
    delete: `${API_ROUTES_BASES.customers}/anonymise`,
    quickSearch: `${API_ROUTES_BASES.customers}/quick-search`
  },
  downloads: {
    order: '/exports/purchase-order',
    orderEvent: '/exports/invoice-event',
    multipleOrders: '/exports/purchase-order-multiple'
  },
  emailTypes: {
    root: `${API_ROUTES_BASES.emailTypes}`
  },
  exports: {
    root: `${API_ROUTES_BASES.exports}`,
    restock: `${API_ROUTES_BASES.exports}/picklist/from-restock`,
    customerList: `${API_ROUTES_BASES.exports}/customer-list`,
    stock: `${API_ROUTES_BASES.exports}/store-labels-from-stock`
  },
  fidelityPoints: {
    root: `${API_ROUTES_BASES.fidelityPoints}`,
    vouchers: `api/fidelity-point-vouchers`
  },
  giftCard: {
    root: `${API_ROUTES_BASES.giftCard}/`,
    activate: `${API_ROUTES_BASES.giftCard}/activate`,
    consume: `${API_ROUTES_BASES.giftCard}/consume`
  },
  inventories: {
    root: `${API_ROUTES_BASES.inventories}`
  },
  paybacks: {
    root: `${API_ROUTES_BASES.paybacks}`
  },
  pricingTags: {
    root: `${API_ROUTES_BASES.pricingTags}`
  },
  printExclusions: {
    root: `${API_ROUTES_BASES.printExclusions}`
  },
  ranges: {
    root: `${API_ROUTES_BASES.ranges}`,
    id: `${API_ROUTES_BASES.ranges}/`
  },
  retailers: {
    root: `${API_ROUTES_BASES.retailers}`,
    toCustomer: `${API_ROUTES_BASES.retailers}/transform-to-customer`
  },
  sealedProducts: {
    root: `${API_ROUTES_BASES.sealedProducts}`,
    stats: `${API_ROUTES_BASES.sealedProducts}/stats`,
    stockPlace: `${API_ROUTES_BASES.sealedProducts}/stock-place`
  },

  serviceNote: {
    directionMessage: `${API_ROUTES_BASES.serviceNote}/direction-message`,
    storeMessages: `${API_ROUTES_BASES.serviceNote}/store-messages`,
    storeMessage: `${API_ROUTES_BASES.serviceNote}/store-message`,
    usefulContacts: `${API_ROUTES_BASES.serviceNote}/useful-contacts`,
    usefulLinks: `${API_ROUTES_BASES.serviceNote}/useful-links`,
    root: `${API_ROUTES_BASES.serviceNote}/`
  },
  stocks: {
    root: `${API_ROUTES_BASES.stocks}`,
    validate: `${API_ROUTES_BASES.stocks}/validate`,
    cancelValidate: `${API_ROUTES_BASES.stocks}/cancel-validate`,
    treat: `${API_ROUTES_BASES.stocks}/treat`,
    cancelTreat: `${API_ROUTES_BASES.stocks}/cancel-treat`,
    stats: `${API_ROUTES_BASES.stocks}/stats`
  },
  restockPrints: {
    root: `${API_ROUTES_BASES.restockPrints}`
  },
  stocksEntries: {
    root: `${API_ROUTES_BASES.stocksEntries}`,
    transferredFrom: `${API_ROUTES_BASES.stocksEntries}/transferred-from`
  },
  stores: {
    root: `${API_ROUTES_BASES.stores}`
  },
  storeHours: {
    root: `${API_ROUTES_BASES.storeHours}`
  },
  storeExceptionalHours: {
    root: `${API_ROUTES_BASES.storeExceptionalHours}`
  },
  storePlaceOrders: {
    root: `${API_ROUTES_BASES.storePlaceOrders}`
  },
  storeEvents: {
    root: `${API_ROUTES_BASES.storeEvents}`,
    close: `${API_ROUTES_BASES.storeEvents}/close`,
    clone: `${API_ROUTES_BASES.storeEvents}/clone-of`,
    deliverVouchers: `${API_ROUTES_BASES.storeEvents}/deliver-vouchers`,
    cancel: `${API_ROUTES_BASES.storeEvents}/cancel`
  },
  storeEventCustomers: {
    root: `${API_ROUTES_BASES.storeEventCustomers}`,
    cancel: `${API_ROUTES_BASES.storeEventCustomers}/cancel`
  },
  storeEventOrders: {
    root: `${API_ROUTES_BASES.storeEventOrders}`
  },
  storeZones: {
    root: `${API_ROUTES_BASES.storeZones}`
  },
  transfers: {
    root: `${API_ROUTES_BASES.transfers}`
  },
  orders: {
    root: `${API_ROUTES_BASES.orders}`,
    cancel: `${API_ROUTES_BASES.orders}/cancel-with-refund`,
    cancelSend: `${API_ROUTES_BASES.orders}/cancel-send`,
    cancelReady: `${API_ROUTES_BASES.orders}/cancel-ready`,
    cancelTreat: `${API_ROUTES_BASES.orders}/cancel-treat`,
    reorder: `${API_ROUTES_BASES.orders}/clone-of`,
    split: `${API_ROUTES_BASES.orders}/split`,
    deleteEntries: `${API_ROUTES_BASES.orders}/delete-entries`,
    excludedFromPrint: `${API_ROUTES_BASES.orders}/excluded-from-prints`,
    deleteVouchers: `${API_ROUTES_BASES.orders}/delete-vouchers`,
    deleteCoupon: `${API_ROUTES_BASES.orders}/delete-coupon`,
    email: `${API_ROUTES_BASES.orders}/email`,
    quickSearch: `${API_ROUTES_BASES.orders}/quick-search`,
    totalOrdersPrint: `${API_ROUTES_BASES.orders}/purchase-order-multiple/count`,
    secondaryStatus: `${API_ROUTES_BASES.orders}/secondary-status`,
    creditNoteCreate: `${API_ROUTES_BASES.orders}/credit-note-create`,
    assignBillNumber: `${API_ROUTES_BASES.orders}/assign-bill-number`
  },
  orderCoupons: {
    root: `${API_ROUTES_BASES.orderCoupons}`
  },
  orderPayments: {
    root: `${API_ROUTES_BASES.orderPayments}`
  },
  orderPrints: {
    root: `${API_ROUTES_BASES.orderPrints}`
  },
  orderEntries: {
    root: `${API_ROUTES_BASES.orderEntries}`,
    reportStockError: `${API_ROUTES_BASES.orderEntries}/report-stock-error`
  },
  paymentMode: {
    root: `${API_ROUTES_BASES.paymentMode}`
  },
  postalRelays: {
    root: `${API_ROUTES_BASES.postalRelays}`
  },
  productCategories: {
    root: `${API_ROUTES_BASES.productCategories}`
  },
  productStats: {
    root: `${API_ROUTES_BASES.productStats}`
  },
  productStores: {
    root: `${API_ROUTES_BASES.productStores}`
  },
  productCreationRequests: {
    root: `${API_ROUTES_BASES.productCreationRequests}`,
    validate: `${API_ROUTES_BASES.productCreationRequests}/validate`,
    reject: `${API_ROUTES_BASES.productCreationRequests}/reject`
  },
  taxes: {
    root: `${API_ROUTES_BASES.taxes}`
  },
  langs: {
    root: `${API_ROUTES_BASES.langs}`
  },
  litigations: {
    root: `${API_ROUTES_BASES.litigations}`
  },
  litigationCategories: {
    root: `${API_ROUTES_BASES.litigationCategories}`
  },
  logEntries: {
    root: `${API_ROUTES_BASES.logEntries}`
  },
  shippingModes: {
    root: `${API_ROUTES_BASES.shippingModes}`,
    availableForOrder: `${API_ROUTES_BASES.shippingModes}/available-for-order`
  },
  vouchers: {
    root: `${API_ROUTES_BASES.vouchers}`,
    fromFidelity: `${API_ROUTES_BASES.vouchers}/from-fidelity`
  },
  voucherCategories: {
    root: `${API_ROUTES_BASES.voucherCategories}`
  },
  commercialOffers: {
    root: `${API_ROUTES_BASES.commercialOffers}`
  },
  shippingLabelsLog: {
    root: `${API_ROUTES_BASES.shippingLabelsLog}`
  },
  roles: {
    root: `${API_ROUTES_BASES.roles}`
  },
  roleActions: {
    root: `${API_ROUTES_BASES.roleActions}`
  }
}

export const PLAYIN_SUPPORT_EMAIL = 'informatique@play-in.com'
export const WAREHOUSE_STORE_ID = '/api/stores/0'
export const BNF_STORE_ID = '/api/stores/1'
export const ANNECY_STORE_ID = '/api/stores/2'
export const DEFAULT_CASHBOX_VALUE = 150
export const DEFAULT_COUNTRY_CODE = 'FR'
export const COLISHIP_TRACKING_URL = 'https://www.colissimo.fr/entreprise/colis/#/parcel-tracking/parcel?cab='
export const CHRONOPOST_TRACKING_URL_START = 'https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT='
export const CHRONOPOST_TRACKING_URL_END =
  '&langue=fr&op=&form_build_id=form-yD1IGcGJwHje8TLMOGH49FZ9x1_aI6P0gZbiIyN3zLA&form_id=chrono_suivi_colis_block_slid_block_form'
