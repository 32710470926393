import { editOrder, GetOrderByIdResponse, orderByIdQueryKey } from 'api/orders'
import Button from 'components/Button/Button'
import InputRadio from 'components/InputRadio/InputRadio'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { SaveIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { UnmodifiableOrderStatuses } from 'types/enums'
import { OrderSecondaryStatus, Role } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'
import { PraparationInputRadioContainer, TwoColGridRow } from './PreparationCardSection.style'
import { useCardAssemblingOptions, useCardCheckingOptions, useCollaboratorsOptions } from './PreparationOptions'

interface PreparationCardSectionProps {
  order?: GetOrderByIdResponse
}

interface PreparationCardSectionInputs {
  cardPreparedSecondaryStatus: OrderSecondaryStatus | undefined
  cardCheckedSecondaryStatus: OrderSecondaryStatus | undefined
  cardPreparer: string | null
  cardChecker: string | null
  cardInTransit: boolean
}

export default function PreparationCardSection({ order }: PreparationCardSectionProps) {
  const { t } = useTranslation()
  const { control, handleSubmit } = useForm<PreparationCardSectionInputs>({
    mode: 'onBlur',
    defaultValues: {
      cardCheckedSecondaryStatus: order?.cardCheckedSecondaryStatus,
      cardPreparedSecondaryStatus: order?.cardPreparedSecondaryStatus,
      cardPreparer: order?.cardPreparer?.['@id'],
      cardChecker: order?.cardChecker?.['@id'],
      cardInTransit: !!order?.cardInTransit
    }
  })
  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order?.id}`)
  const { mutate, isLoading } = useMutation((body: PreparationCardSectionInputs) => editOrder(body, order?.id!), {
    onSuccess: (data) => {
      const prev = queryClient.getQueryData<Order>(key)
      queryClient.setQueryData(key, { ...prev, ...data })
      document.getElementById('orderTrackingNumberInput')?.focus()
    }
  })

  const onSubmit = (data: PreparationCardSectionInputs) => {
    mutate({
      cardInTransit: data.cardInTransit,
      cardCheckedSecondaryStatus: data.cardCheckedSecondaryStatus || undefined,
      cardPreparedSecondaryStatus: data.cardPreparedSecondaryStatus || undefined,
      cardPreparer: data.cardPreparer || null,
      cardChecker: data.cardChecker || null
    })
  }
  const { options: cardAssemblingOptions } = useCardAssemblingOptions()
  const { options: cardCheckingOptions } = useCardCheckingOptions()

  const { options: preparatorsOptions, paginationProps: preparatorsPaginationProps } = useCollaboratorsOptions({
    stores: `${order?.stock?.id}`,
    'collaboratorRoles.roleName': Role.ROLE_PREPARATION_CARDS,
    'order[id]': SortingDirection.Asc
  })
  const actualPrepator = order?.cardPreparer
    ? {
        label: `${order?.cardPreparer.id} - ${order?.cardPreparer.lastname} ${order?.cardPreparer.firstname}`,
        value: order?.cardPreparer['@id'] as string
      }
    : null
  const fullPreparatorsOptions = actualPrepator ? [...preparatorsOptions, actualPrepator] : preparatorsOptions

  const { options: checkerOptions, paginationProps: checkerPaginationProps } = useCollaboratorsOptions({
    stores: `${order?.stock?.id}`,
    'collaboratorRoles.roleName': Role.ROLE_PACKAGING_CARDS,
    'order[id]': SortingDirection.Asc
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title2>{t('page.order.detail.tab.prepare.cardSectionTitle')}</Title2>
      <TwoColGridRow>
        <Select
          id="cardPreparedSecondaryStatus"
          label={t('page.order.detail.tab.prepare.cardAssemblingLabel')}
          placeholder={t('page.order.detail.tab.prepare.statusPlaceholder')}
          options={cardAssemblingOptions}
          control={control}
          enableUnselect
        />
        <Select
          id="cardCheckedSecondaryStatus"
          label={t('page.order.detail.tab.prepare.cardCheckingLabel')}
          placeholder={t('page.order.detail.tab.prepare.statusPlaceholder')}
          options={cardCheckingOptions}
          control={control}
          enableUnselect
        />
        <Select
          id="cardPreparer"
          label={t('page.order.detail.tab.prepare.cardPreparatorLabel')}
          placeholder={t('common.select.defaultOptions.collaborators')}
          options={fullPreparatorsOptions}
          control={control}
          enableUnselect
          {...preparatorsPaginationProps}
        />
        <Select
          id="cardChecker"
          label={t('page.order.detail.tab.prepare.cardCheckerLabel')}
          placeholder={t('common.select.defaultOptions.collaborators')}
          options={checkerOptions}
          control={control}
          enableUnselect
          {...checkerPaginationProps}
        />
        <PraparationInputRadioContainer>
          <InputRadio display="checkbox" control={control} id="cardInTransit" />
          <Text>{t('page.order.detail.tab.prepare.transitLabel')}</Text>
        </PraparationInputRadioContainer>
        <Button
          buttonType="submit"
          shape="circle"
          icon={SaveIcon}
          disabled={UnmodifiableOrderStatuses.includes(order?.status!)}
          isLoading={isLoading}
        />
      </TwoColGridRow>
    </form>
  )
}
