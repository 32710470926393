import { useDownloadFile } from 'api/basePlayInApi'
import { countPurchaseOrders } from 'api/orders'
import { PreviewCounterText } from 'app/OrderPrint/utils.style'
import Button from 'components/Button/Button'
import Loader from 'components/Loader/Loader'
import Title2Tooltip from 'components/TooltipContents/Title2Tooltip'
import { API_ROUTES } from 'constants/configs'
import { PrintIcon } from 'constants/icons'
import { useEmit } from 'hooks/useEventEmitter'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { EventType } from 'types/events'
import { stringify } from 'utils/queryParams'
import { PreviewButtonContainer } from '../../../utils.style'
import { ExportButtonsCardLayout } from '../../otherProductsTab/otherProductsTab.style'

type Props = {
  active: boolean
}

const bnfCardOrdersPayload = {
  storeId: 1,
  params: {
    name: 'Click & Collect Paris BNF cartes',
    preorder: false,
    withCards: true,
    'clickAndCollect[]': [1],
    stand: false
  }
}

const rivoliCardOrdersPayload = {
  storeId: 0,
  params: {
    name: 'Click & Collect Paris Rivoli cartes',
    preorder: false,
    withCards: true,
    'clickAndCollect[]': [3],
    stand: false
  }
}

export default function PrintCardPickup({ active }: Props) {
  const { t } = useTranslation()
  const { downloadFile, isLoading } = useDownloadFile()
  const [loadingButton, setLoadingButton] = useState('')
  const emit = useEmit()

  const { data: bnfCardOrders, isFetching: bnfCardOrdersLoading } = useQuery(
    ['countOrderPrint', bnfCardOrdersPayload],
    () => countPurchaseOrders(bnfCardOrdersPayload.storeId, bnfCardOrdersPayload.params),
    {
      enabled: active
    }
  )

  const { data: rivoliCardOrders, isFetching: rivoliCardOrdersLoading } = useQuery(
    ['countOrderPrint', rivoliCardOrdersPayload],
    () => countPurchaseOrders(rivoliCardOrdersPayload.storeId, rivoliCardOrdersPayload.params),
    {
      enabled: active
    }
  )

  return (
    <ExportButtonsCardLayout>
      <div>
        <Title2Tooltip
          title={t('page.order.print.details.clickAndCollect')}
          text={t('page.order.print.details.tooltips.clickAndCollect')}
        />
        <PreviewButtonContainer>
          <Button
            onClick={async () => {
              setLoadingButton('bnf')
              downloadFile(
                `${API_ROUTES.downloads.multipleOrders}/${bnfCardOrdersPayload.storeId}?${stringify(
                  bnfCardOrdersPayload.params
                )}`
              )
              emit(EventType.PrintOrder, null)
            }}
            shape="default"
            icon={PrintIcon}
            size="long"
            isLoading={isLoading && loadingButton === 'bnf'}
          >
            {t('page.order.print.actions.printClickAndCollectCardsBnf')}
          </Button>
          {!active || bnfCardOrdersLoading ? (
            <Loader />
          ) : (
            <PreviewCounterText>
              {t('page.order.print.details.pendingOrdersCount', { count: bnfCardOrders.totalItems })}
            </PreviewCounterText>
          )}
        </PreviewButtonContainer>
      </div>
      <PreviewButtonContainer>
        <Button
          onClick={async () => {
            setLoadingButton('rivoli')
            downloadFile(
              `${API_ROUTES.downloads.multipleOrders}/${rivoliCardOrdersPayload.storeId}?${stringify(
                rivoliCardOrdersPayload.params
              )}`
            )
            emit(EventType.PrintOrder, null)
          }}
          shape="default"
          icon={PrintIcon}
          size="long"
          isLoading={isLoading && loadingButton === 'rivoli'}
        >
          {t('page.order.print.actions.printClickAndCollectCardsRivoli')}
        </Button>
        {!active || rivoliCardOrdersLoading ? (
          <Loader />
        ) : (
          <PreviewCounterText>
            {t('page.order.print.details.pendingOrdersCount', { count: rivoliCardOrders.totalItems })}
          </PreviewCounterText>
        )}
      </PreviewButtonContainer>
    </ExportButtonsCardLayout>
  )
}
