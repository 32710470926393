import { yupResolver } from '@hookform/resolvers/yup'
import { useDownloadFile } from 'api/basePlayInApi'
import { countPurchaseOrders } from 'api/orders'
import { PreviewCounterText } from 'app/OrderPrint/utils.style'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import InputRadio from 'components/InputRadio/InputRadio'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import { ColumnContainer } from 'components/Layouts/InputLayout/InputLayout.styles'
import Loader from 'components/Loader/Loader'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { API_ROUTES } from 'constants/configs'
import { PrintIcon } from 'constants/icons'
import { useEmit } from 'hooks/useEventEmitter'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { SealedProduct } from 'types/entities'
import { EventType } from 'types/events'
import { stringify } from 'utils/queryParams'
import * as yup from 'yup'
import { Languages, OrderType } from '../../types'
import { getLanguageParams, getOrderTypeParams, getPrintName } from '../../utils'
import { PreviewButtonContainer } from '../../utils.style'
import {
  CheckboxLayout,
  FieldsWrapper,
  OptionsGrid,
  OptionsWrapper,
  PrintOptionContainer,
  PrintPreorderContainer
} from './PreorderPrintForm.styles'
import ProductsSelector from './ProductsSelector'
import SearchByEditionForm from './SearchByEditionForm'
import SearchByReleaseDateForm from './SearchByReleaseDateForm'
import SearchProductForm from './SearchProductForm'

interface Props {
  storeId: string
  active: boolean
}

export type SearchQuery = {
  isLoading?: boolean
  edition?: string | null
}

const schema = yup.object({
  orderType: yup.mixed<OrderType>().oneOf(Object.values(OrderType)).required(),
  withCards: yup.boolean().nullable(),
  withProducts: yup.boolean().nullable(),
  preorder: yup.boolean(),
  linkEditionOnly: yup.boolean(),
  selectedProductOnly: yup.boolean(),
  singleProductOrder: yup.boolean(),
  noOtherPreorderProduct: yup.boolean(),
  sendOnlyFastShipping: yup.boolean(),
  sendPrintEmail: yup.boolean(),
  cardLanguage: yup.mixed<Languages>().oneOf(Object.values(Languages))
})

export interface PreorderFormInputs extends yup.InferType<typeof schema> {}

const PreorderPrintForm = ({ storeId, active }: Props) => {
  const emit = useEmit()
  const { t } = useTranslation()
  const { downloadFile, isLoading: downloading } = useDownloadFile()
  const [shouldCount, setShouldCount] = useState(false)
  const [allCards, setAllCards] = useState(false)

  const [resetTrigger, setResetTrigger] = useState<string | number>('')
  const [productsList, setProductsList] = useState<SealedProduct[]>([])
  const [selectedProducts, setSelectedProducts] = useState<number[]>([])

  const [productsFromAutocomplete, setProductsFromAutocomplete] = useState<SealedProduct[]>([])

  const [searchFromEditionStatus, setSearchFromEditionStatus] = useState<SearchQuery>({})
  const [productsFromReleaseDate, setProductsFromReleaseDate] = useState<SealedProduct[]>([])
  const { isLoading: fromEditionLoading, edition: searchedEdition } = searchFromEditionStatus

  const [searchFromReleaseDateStatus, setSearchFromReleaseDateStatus] = useState<SearchQuery>({})
  const { isLoading: fromReleaseDateLoading } = searchFromReleaseDateStatus
  const [productsFromEdition, setProductsFromEdition] = useState<SealedProduct[]>([])

  const isLoading = fromEditionLoading || fromReleaseDateLoading

  const handleProducts = useCallback(
    (searchFrom: 'releaseDate' | 'productSearch' | 'edition', searchedProducts?: SealedProduct[]) => {
      if (searchedProducts) {
        // Due to the next step, we need to keep in memory data, sorted by origin
        if (searchFrom === 'releaseDate') setProductsFromReleaseDate(searchedProducts)
        if (searchFrom === 'edition') setProductsFromEdition(searchedProducts)
        if (searchFrom === 'productSearch') {
          setProductsFromAutocomplete((prev) => _.uniqBy([...prev, ...searchedProducts], 'id'))
          if (searchedProducts[0]) {
            setSelectedProducts((prev) => _.uniq([...prev, searchedProducts[0].id!]))
          }
        }

        // We need to set non-duplicate data on array start
        setProductsList((prev) => {
          // Set data which are not supposed to be erased
          let notFromSearch: SealedProduct[] = []

          if (searchFrom === 'releaseDate') notFromSearch = [...productsFromEdition, ...productsFromAutocomplete]
          if (searchFrom === 'edition') notFromSearch = [...productsFromReleaseDate, ...productsFromAutocomplete]
          if (searchFrom === 'productSearch')
            notFromSearch = [...productsFromReleaseDate, ...productsFromEdition, ...productsFromAutocomplete]

          // Find non-duplicate new data
          let newProducts = searchedProducts.filter((product) => !_.some(notFromSearch, { id: product.id }))

          // Keep data from the 2 other search
          let restProducts = prev.filter((product) => _.some(notFromSearch, { id: product.id }))
          return [...newProducts, ...restProducts]
        })
      }
    },
    [productsFromEdition, productsFromReleaseDate, productsFromAutocomplete]
  )

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm<PreorderFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      preorder: false,
      sendPrintEmail: true
    }
  })

  const [
    cardLanguage,
    linkEditionOnly,
    selectedProductOnly,
    singleProductOrder,
    orderType,
    sendOnlyFastShipping,
    sendPrintEmail,
    preorder,
    withCards,
    withProducts,
    noOtherPreorderProduct
  ] = watch([
    'cardLanguage',
    'linkEditionOnly',
    'selectedProductOnly',
    'singleProductOrder',
    'orderType',
    'sendOnlyFastShipping',
    'sendPrintEmail',
    'preorder',
    'withCards',
    'withProducts',
    'noOtherPreorderProduct'
  ])

  //Form handling
  useEffect(() => {
    // Hide and reset count query because params will change
    setShouldCount(false)

    if (withCards === false) {
      setValue('cardLanguage', undefined)
      setValue('linkEditionOnly', false)
      setValue('withCards', false)
    }

    if (withProducts === false) {
      setValue('withProducts', false)
      setValue('selectedProductOnly', false)
      setValue('singleProductOrder', false)
      setValue('noOtherPreorderProduct', false)
    }
  }, [
    cardLanguage,
    linkEditionOnly,
    selectedProductOnly,
    singleProductOrder,
    orderType,
    preorder,
    withCards,
    withProducts,
    productsList,
    selectedProducts,
    noOtherPreorderProduct,
    setValue,
    searchedEdition,
    allCards
  ])

  const preOrderPayload = useMemo(() => {
    const releasedDateMax = _.chain(productsList)
      .filter((product) => selectedProducts.includes(product.id!))
      .orderBy('releasedAt', 'desc')
      .head()
      .get('releasedAt')
      .value()

    const params: any = {
      name: getPrintName({ orderType, preorder, withCards, withProducts }),
      preorder,
      sendOnlyFastShipping,
      sendPrintEmail,
      releasedDateMax: withProducts === false ? undefined : releasedDateMax,
      withCards: _.isBoolean(withCards) ? withCards : undefined,
      withProducts: _.isBoolean(withProducts) ? withProducts : undefined,
      stand: orderType === OrderType.ClickAndCollectStand ? true : false,
      'products[]': withProducts === false || preorder === false ? undefined : selectedProducts,
      cardEdition: allCards ? searchedEdition : undefined,
      linkEditionOnly: linkEditionOnly === true ? true : undefined,
      selectedProductOnly: selectedProductOnly === true ? true : undefined,
      singleProductOrder: singleProductOrder === true ? true : undefined,
      noOtherPreorderProduct: noOtherPreorderProduct === true ? true : undefined,
      ...getOrderTypeParams(orderType),
      ...getLanguageParams(cardLanguage)
    }

    return params
  }, [
    cardLanguage,
    linkEditionOnly,
    selectedProductOnly,
    singleProductOrder,
    orderType,
    sendPrintEmail,
    sendOnlyFastShipping,
    preorder,
    withCards,
    withProducts,
    productsList,
    selectedProducts,
    searchedEdition,
    noOtherPreorderProduct,
    allCards
  ])

  const onSubmit = async () => {
    await downloadFile(`${API_ROUTES.downloads.multipleOrders}/0?${stringify(preOrderPayload)}`)
    emit(EventType.PrintOrder, null)
    //Reset form state
    setAllCards(false)
    setResetTrigger(Date.now()) // set a random string and pass it to nestedForm to trigger a reset
    setSelectedProducts([])
    setProductsList([])
    setShouldCount(false)
    reset({
      preorder
    })
  }

  const { data: preOrders, isFetching: preOrdersLoading } = useQuery(
    ['countOrderPrint', storeId, preOrderPayload],
    () => countPurchaseOrders(storeId, preOrderPayload),
    {
      enabled: shouldCount
    }
  )

  const handleAllProductCheckbox = useCallback(
    () => setSelectedProducts((prev) => (prev.length === productsList.length ? [] : productsList.map((e) => e.id!))),
    [productsList]
  )

  return (
    <>
      <PrintOptionContainer>
        <ColumnContainer>
          <Select
            id="orderType"
            control={control}
            label={t('page.order.print.details.labels.orderType.label')}
            tooltip={<TextTooltip id="orderType" text={t('page.order.print.details.labels.orderType.tooltip')} />}
            placeholder={t('common.select.defaultOptions.type')}
            className={classNames({ 'is-invalid': errors.orderType })}
            options={[
              { value: OrderType.Sellermania, label: t('page.order.print.details.labels.orderType.sellermania') },
              { value: OrderType.Classic, label: t('page.order.print.details.labels.orderType.classic') },
              { value: OrderType.ClickAndCollectBnf, label: t('page.order.print.details.labels.orderType.bnf') },
              { value: OrderType.ClickAndCollectRivoli, label: t('page.order.print.details.labels.orderType.rivoli') },
              { value: OrderType.ClickAndCollectAnnecy, label: t('page.order.print.details.labels.orderType.annecy') },
              { value: OrderType.ClickAndCollectStand, label: t('page.order.print.details.labels.orderType.stand') }
            ]}
          />
          <BasicFieldValidationError
            error={errors.orderType}
            message={t('page.order.print.details.labels.orderType.error')}
          />
        </ColumnContainer>

        <InputRadio
          id="withCards"
          label={t('page.order.print.details.labels.withCards.label')}
          labelAccept={t('page.order.print.details.labels.withCards.accept')}
          labelDecline={t('page.order.print.details.labels.withCards.decline')}
          tooltip={
            <Tooltip id="withCards">
              <Trans i18nKey="page.order.print.details.labels.withCards.tooltip" />
            </Tooltip>
          }
          control={control}
          display="checkbox"
          enableUnselect
          swapOpts
        />

        <InputRadio
          id="withProducts"
          label={t('page.order.print.details.labels.withProducts.label')}
          labelAccept={t('page.order.print.details.labels.withProducts.accept')}
          labelDecline={t('page.order.print.details.labels.withProducts.decline')}
          tooltip={
            <Tooltip id="withProducts">
              <Trans i18nKey="page.order.print.details.labels.withProducts.tooltip" />
            </Tooltip>
          }
          control={control}
          display="checkbox"
          enableUnselect
          swapOpts
        />
        <InputRadio
          control={control}
          id="sendOnlyFastShipping"
          label={t('page.order.print.details.labels.sendOnlyFastShipping')}
          display="checkbox"
          layout="row-reverse"
          tooltip={
            <Tooltip id="sendOnlyFastShippingTooltip">
              {t('page.collection.create.tooltips.sendOnlyFastShippingTooltip')}
            </Tooltip>
          }
        />

        <InputRadio
          control={control}
          id="sendPrintEmail"
          label={t('page.order.print.details.labels.sendPrintEmail')}
          display="checkbox"
          layout="row-reverse"
        />

        <InputRadio
          id="preorder"
          label={t('page.order.print.details.labels.preorder.label')}
          labelAccept={t('page.order.print.details.labels.preorder.preorder')}
          labelDecline={t('page.order.print.details.labels.preorder.available')}
          tooltip={
            <Tooltip id="preorder">
              <Trans i18nKey="page.order.print.details.labels.preorder.tooltip" />
            </Tooltip>
          }
          control={control}
          swapOpts
        />
      </PrintOptionContainer>

      {preorder && (
        <PrintPreorderContainer>
          <FieldsWrapper>
            <SearchByEditionForm
              handleSearchStatus={setSearchFromEditionStatus}
              handleProducts={handleProducts}
              storeId={storeId}
              active={active}
              disabled={withProducts === false}
              resetTrigger={resetTrigger}
            />
            <SearchByReleaseDateForm
              handleSearchStatus={setSearchFromReleaseDateStatus}
              handleProducts={handleProducts}
              storeId={storeId}
              active={active}
              disabled={withProducts === false}
              resetTrigger={resetTrigger}
            />
            <SearchProductForm
              onSelect={handleProducts}
              storeId={storeId}
              disabled={withProducts === false}
              resetTrigger={resetTrigger}
            />
          </FieldsWrapper>

          {(productsList.length > 0 || (withCards !== false && searchedEdition)) && (
            <OptionsWrapper>
              <RowContainer>
                <Text>{t('page.order.print.details.preorder.allProducts.title')}</Text>
                <Tooltip id="allProducts">
                  <Trans i18nKey="page.order.print.details.preorder.allProducts.tooltips" />
                </Tooltip>
              </RowContainer>
              <OptionsGrid>
                {withCards !== false && (
                  <CheckboxLayout>
                    <Checkbox
                      className={classNames({ checked: allCards })}
                      onClick={() => setAllCards((prev) => !prev)}
                    />
                    <Text>{t('page.order.print.details.preorder.allCards')}</Text>
                  </CheckboxLayout>
                )}
                {withProducts !== false && (
                  <CheckboxLayout>
                    <Checkbox
                      className={classNames({ checked: selectedProducts.length === productsList.length })}
                      onClick={() => handleAllProductCheckbox()}
                    />
                    <Text>{t('page.order.print.details.preorder.allProducts.label')}</Text>
                  </CheckboxLayout>
                )}
              </OptionsGrid>
              {withProducts !== false && (
                <ProductsSelector
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                  productsList={productsList}
                />
              )}
            </OptionsWrapper>
          )}
          <OptionsWrapper>
            <RowContainer>
              <Text>{t('page.order.print.details.preorder.orderContent.label')}</Text>
              <Tooltip id="orderContentTooltip">
                <Trans i18nKey="page.order.print.details.preorder.orderContent.tooltip" />
              </Tooltip>
            </RowContainer>

            <OptionsGrid>
              <InputRadio
                id="linkEditionOnly"
                label={t('page.order.print.details.preorder.linkEditionOnly')}
                display="checkbox"
                layout="row-reverse"
                control={control}
                disabled={withCards === false}
              />

              <InputRadio
                display="checkbox"
                label={t('page.order.print.details.preorder.selectedProductOnly')}
                control={control}
                id="selectedProductOnly"
                layout="row-reverse"
                disabled={withProducts === false}
              />

              <InputRadio
                display="checkbox"
                label={t('page.order.print.details.preorder.singleProductOrder')}
                control={control}
                id="singleProductOrder"
                layout="row-reverse"
                disabled={withProducts === false}
              />
              <Select
                id="cardLanguage"
                label={t('page.order.print.details.preorder.cardLanguage')}
                control={control}
                enableUnselect={true}
                placeholder={t('common.select.defaultOptions.lang')}
                disabled={withCards === false}
                options={[
                  { value: Languages.Fr, label: t('common.languages.french') },
                  { value: Languages.En, label: t('common.languages.english') },
                  { value: Languages.FrEn, label: t('common.languages.frEn') },
                  { value: Languages.All, label: t('page.order.print.details.preorder.allLanguages') }
                ]}
              />
              <InputLayout label={<></>} className="no-border">
                <InputRadio
                  display="checkbox"
                  label={t('page.order.print.details.preorder.excludeOtherPreorder')}
                  control={control}
                  id="noOtherPreorderProduct"
                  layout="row-reverse"
                  disabled={withProducts === false}
                />
              </InputLayout>
            </OptionsGrid>
          </OptionsWrapper>
        </PrintPreorderContainer>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <PreviewButtonContainer>
          <Button
            variant={preorder ? 'warning' : 'default'}
            icon={PrintIcon}
            buttonType="submit"
            isLoading={isLoading || downloading}
            size="long"
            disabled={preorder ? !allCards && !selectedProducts.length : false}
          >
            {preorder ? t('page.order.print.actions.printPreorder') : t('common.label.print')}
          </Button>
          <Button
            variant="white"
            shape="circle"
            size="default"
            onClick={() => setShouldCount(true)}
            disabled={preorder ? !orderType || (!allCards && !selectedProducts.length) : !orderType}
          >
            <Text size="2rem" fontWeight="bold">
              #
            </Text>
          </Button>
          {shouldCount &&
            (preOrdersLoading ? (
              <Loader />
            ) : (
              <PreviewCounterText>
                {t('page.order.print.details.pendingOrdersCount', { count: preOrders?.totalItems || 0 })}
              </PreviewCounterText>
            ))}
        </PreviewButtonContainer>
      </form>
    </>
  )
}

export default PreorderPrintForm
