import { yupResolver } from '@hookform/resolvers/yup'
import { useGetEditions } from 'api/cardEditions'
import { GetPrintExclusionsQueryResponse, getPrintExclusionsUri } from 'api/printExclusions'
import { fetchSealedProducts, getSealedProductsUri } from 'api/sealedProducts'
import { ColumnContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Select from 'components/Select/Select'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { SealedProduct } from 'types/entities'
import { isObjectEmpty } from 'utils/queryParams'
import * as yup from 'yup'
import { SearchQuery } from './PreorderPrintForm'
import useOtherProductsSearchFilter, { PreorderSearchFormInputs } from './useOtherProductsSearchFilter'

const schema = yup.object().shape({
  edition: yup.string().trim().nullable(true),
  language: yup.string().trim().nullable(true)
})

interface Props {
  handleSearchStatus: (search: SearchQuery) => void
  handleProducts: (searchFrom: 'releaseDate' | 'productSearch' | 'edition', searchedProducts?: SealedProduct[]) => void
  storeId: string
  active: boolean
  disabled?: boolean
  resetTrigger?: string | number
}
export default function SearchByEditionForm({
  handleSearchStatus,
  handleProducts,
  storeId,
  active,
  disabled = false,
  resetTrigger
}: Props) {
  const { t } = useTranslation()
  const { control, watch, setValue, reset } = useForm<PreorderSearchFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      edition: null,
      language: null
    }
  })
  const [watchEdition] = watch(['edition'])
  const { queryParams, langOptions } = useOtherProductsSearchFilter(watch)
  const { data: editions } = useGetEditions(
    { 'releasedAt[after]': 'now', 'releasedAt[before]': '2 month' },
    {
      enabled: active
    }
  )

  const { data: printExclusions, isLoading: isExclusionLoading } = usePaginatedQuery<GetPrintExclusionsQueryResponse>(
    ['excluded-from-print-editions', storeId],
    getPrintExclusionsUri({
      store: storeId,
      pagination: false
    }),
    {
      enabled: active
    }
  )

  const { isLoading } = useQuery(
    [queryParams, storeId],
    () =>
      fetchSealedProducts(
        getSealedProductsUri({
          'exists[secondHandOf]': false,
          itemsPerPage: 150,
          ...queryParams
        })
      ),
    {
      enabled: active && !isObjectEmpty(queryParams),
      onSuccess: (data) => {
        handleProducts('edition', data)
      }
    }
  )

  useEffect(() => reset(), [storeId, reset, resetTrigger])

  useEffect(() => {
    handleSearchStatus({ isLoading, edition: watchEdition })
  }, [isLoading, handleSearchStatus, watchEdition])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!value.edition && name === 'edition') {
        setValue('language', '')
        handleProducts('edition', [])
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue, handleProducts])

  return (
    <ColumnContainer>
      <Select
        options={(editions || []).map((option) => ({
          label: option.name,
          value: option.id,
          disabled: (printExclusions ?? [])?.map((e) => e.edition?.['@id']).includes(option['@id'])
        }))}
        control={control}
        id="edition"
        enableUnselect={true}
        placeholder={t('common.select.defaultOptions.cardEdition')}
        isLoading={isExclusionLoading || isLoading}
        label={t('common.label.cardEdition')}
      />
      <Select
        id="language"
        options={langOptions}
        label={t('page.order.print.details.preorder.productLanguage')}
        control={control}
        enableUnselect={true}
        placeholder={t('common.select.defaultOptions.lang')}
        disabled={!watchEdition || disabled}
      />
    </ColumnContainer>
  )
}
