import { yupResolver } from '@hookform/resolvers/yup'
import { fetchSealedProducts, getSealedProductsUri } from 'api/sealedProducts'
import DatePicker from 'components/DatePicker/DatePicker'
import InputRadio from 'components/InputRadio/InputRadio'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { SealedProduct, Store } from 'types/entities'
import { isObjectEmpty } from 'utils/queryParams'
import * as yup from 'yup'
import useOtherProductsSearchFilter, { PreorderSearchFormInputs } from '../hooks/useOtherProductsSearchFilter'
import { SearchQuery } from '../OtherProductPreorderForm'
import { FieldsColWithBorder } from '../otherProductsTab.style'

const schema = yup.object().shape({
  releaseDate: yup.date().nullable(true),
  includeTCG: yup.boolean().nullable(true)
})

interface Props {
  handleSearchStatus: (search: SearchQuery) => void
  handleProducts: (searchFrom: 'releaseDate' | 'productSearch' | 'edition', searchedProducts?: SealedProduct[]) => void
  store: Store
  active: boolean
}

export default function SearchByReleaseDateForm({ handleSearchStatus, handleProducts, store, active }: Props) {
  const { t } = useTranslation()
  const { control, watch, setValue, trigger, register, reset, getValues } = useForm<PreorderSearchFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      searchProduct: null,
      releaseDate: '',
      includeTCG: null
    }
  })

  useEffect(() => reset(), [store, reset])

  const [watchRelease, watchIncludeTCG] = watch(['releaseDate', 'includeTCG'])
  const { queryParams } = useOtherProductsSearchFilter(watch)

  const { isLoading, isFetched } = useQuery(
    [queryParams, store],
    () =>
      fetchSealedProducts(
        getSealedProductsUri({
          'exists[secondHandOf]': false,
          itemsPerPage: 150,
          ...queryParams
        })
      ),
    {
      enabled: active && !isObjectEmpty(queryParams),
      onSuccess: (data) => {
        handleProducts('releaseDate', data)
      }
    }
  )

  useEffect(() => {
    handleSearchStatus({ isLoading, isFetched })
  }, [isLoading, isFetched, handleSearchStatus])

  // Initialize includeTCG to null to avoid query fetching
  // when releaseDate change for the first time, set includeTCG to false
  useEffect(() => {
    if (watchRelease && watchIncludeTCG === null) {
      setValue('includeTCG', false)
    }
  }, [watchRelease, watchIncludeTCG, setValue])

  return (
    <FieldsColWithBorder>
      <DatePicker
        id="releaseDate"
        trigger={trigger}
        register={register}
        label={t('common.label.releaseDate')}
        setValue={setValue}
        defaultValue={getValues('releaseDate') ?? null}
      />
      <InputRadio
        label={t('page.order.print.details.labels.includeTCG')}
        control={control}
        id="includeTCG"
        disabled={!watchRelease}
      />
    </FieldsColWithBorder>
  )
}
