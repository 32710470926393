import { yupResolver } from '@hookform/resolvers/yup'
import { fetchSealedProducts, getSealedProductsUri } from 'api/sealedProducts'
import DatePicker from 'components/DatePicker/DatePicker'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { SealedProduct } from 'types/entities'
import { isObjectEmpty } from 'utils/queryParams'
import * as yup from 'yup'
import { SearchQuery } from './PreorderPrintForm'
import useOtherProductsSearchFilter, { PreorderSearchFormInputs } from './useOtherProductsSearchFilter'

const schema = yup.object().shape({
  releaseDate: yup.date().nullable(true)
})

interface Props {
  handleSearchStatus: (search: SearchQuery) => void
  handleProducts: (searchFrom: 'releaseDate' | 'productSearch' | 'edition', searchedProducts?: SealedProduct[]) => void
  storeId: string
  active: boolean
  disabled: boolean
  resetTrigger?: string | number
}

export default function SearchByReleaseDateForm({
  handleSearchStatus,
  handleProducts,
  storeId,
  active,
  disabled = false,
  resetTrigger
}: Props) {
  const { t } = useTranslation()
  const { watch, setValue, trigger, register, reset, getValues } = useForm<PreorderSearchFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      searchProduct: null,
      releaseDate: ''
    }
  })

  useEffect(() => reset(), [storeId, reset, resetTrigger])

  const { queryParams } = useOtherProductsSearchFilter(watch)

  const { isLoading } = useQuery(
    [queryParams, storeId],
    () =>
      fetchSealedProducts(
        getSealedProductsUri({
          'exists[secondHandOf]': false,
          itemsPerPage: 150,
          ...queryParams
        })
      ),
    {
      enabled: active && !isObjectEmpty(queryParams),
      onSuccess: (data) => {
        handleProducts('releaseDate', data)
      }
    }
  )

  useEffect(() => {
    handleSearchStatus({ isLoading })
  }, [isLoading, handleSearchStatus])

  return (
    <DatePicker
      id="releaseDate"
      trigger={trigger}
      register={register}
      label={t('page.order.print.details.preorder.releaseDate')}
      setValue={setValue}
      defaultValue={getValues('releaseDate') ?? null}
      disabled={disabled}
    />
  )
}
