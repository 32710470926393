import { yupResolver } from '@hookform/resolvers/yup'
import { patchSecondaryStatus } from 'api/orders'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { ArrowIcon, CheckIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import * as yup from 'yup'
import {
  Arrow,
  FormLayout,
  OrdrePreparationLayout,
  OrdrePreparationWrapper,
  Separator,
  StatusRow
} from './OrderPreparationManage.style'

const schema = yup.object({
  orderId: yup.string().trim().required()
})
interface OrderPreparationInputs extends yup.InferType<typeof schema> {}
export default function OrderPreparationManage() {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<OrderPreparationInputs>({
    resolver: yupResolver(schema)
  })

  const { mutate, isLoading } = useMutation((status: string) => patchSecondaryStatus(status), {
    onSettled: () => reset()
  })

  const onSubmit = (formData: OrderPreparationInputs) => mutate(formData.orderId)

  const statusList = [
    {
      letter: 'C',
      label: t('common.select.order.orderSecondaryStatus.cardAssembledComplete')
    },
    {
      letter: 'V',
      label: t('common.select.order.orderSecondaryStatus.cardAssembledIncomplete')
    },
    {
      letter: 'Z',
      label: t('common.select.order.orderSecondaryStatus.cardPreorder')
    },
    {
      letter: null,
      label: null
    },
    {
      letter: 'S',
      label: t('common.select.order.orderSecondaryStatus.productAssembledComplete')
    },
    {
      letter: 'W',
      label: t('common.select.order.orderSecondaryStatus.productAssembledIncomplete')
    },
    {
      letter: 'R',
      label: t('common.select.order.orderSecondaryStatus.productPreorder')
    },
    {
      letter: null,
      label: null
    },
    {
      letter: 'E',
      label: t('common.select.order.orderSecondaryStatus.productCheckedComplete')
    },
    {
      letter: 'I',
      label: t('common.select.order.orderSecondaryStatus.productCheckedIncomplete')
    }
  ]
  return (
    <Section>
      <MainTitle title={t('page.orderPreparation.manage.title')} />
      <OrdrePreparationWrapper>
        <OrdrePreparationLayout>
          <CardLayout>
            <Title2>{t('page.orderPreparation.manage.formTitle')}</Title2>
            <FormLayout onSubmit={handleSubmit(onSubmit)}>
              <Input
                register={register}
                id="orderId"
                label={t('page.orderPreparation.manage.letterAndNumber')}
                className={classNames({
                  'is-invalid': errors.orderId
                })}
                tooltip={
                  <Tooltip id="orderPreparationTooltip">
                    <Trans i18nKey="page.orderPreparation.manage.tooltip" />
                  </Tooltip>
                }
              />

              <Button icon={CheckIcon} shape="circle" iconColor="white" buttonType="submit" isLoading={isLoading} />
            </FormLayout>
            <BasicFieldValidationError
              error={errors.orderId}
              message={t('page.orderPreparation.manage.requiredNumber')}
            />
          </CardLayout>
          <CardLayout>
            {statusList.map((status, i) =>
              status.letter ? (
                <>
                  <StatusRow>
                    <Text fontWeight="bold" size="2.2rem">
                      {status.letter}
                    </Text>
                    <Arrow svg={ArrowIcon} color="blue3" />
                    <Text fontWeight="bold" size="2.2rem">
                      {status.label}
                    </Text>
                  </StatusRow>
                </>
              ) : (
                <Separator></Separator>
              )
            )}
          </CardLayout>
        </OrdrePreparationLayout>
      </OrdrePreparationWrapper>
    </Section>
  )
}
