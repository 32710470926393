import { useDownloadFile } from 'api/basePlayInApi'
import { countPurchaseOrders } from 'api/orders'
import { PreviewCounterText } from 'app/OrderPrint/utils.style'
import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Loader from 'components/Loader/Loader'
import { Title2 } from 'components/Title/Title.styles'
import { TooltipCol } from 'components/Tooltip/Tooltip.styles'
import Title2Tooltip from 'components/TooltipContents/Title2Tooltip'
import { API_ROUTES } from 'constants/configs'
import { PrintIcon } from 'constants/icons'
import { useEmit } from 'hooks/useEventEmitter'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Store } from 'types/entities'
import { EventType } from 'types/events'
import { stringify } from 'utils/queryParams'
import PrintList from '../../components/OrderPrintList'
import { PreviewButtonContainer } from '../../utils.style'
import PrintPreorder from './components/PrintPreorders'
import OtherProductsSearchForm from './OtherProductPreorderForm'
import { ExportButtonsCardLayout } from './otherProductsTab.style'

interface Props {
  store: Store
  active: boolean
}

const ShippingTooltip = (
  <TooltipCol>
    <Trans i18nKey="page.order.print.otherProduct.tooltips.warehouseShipping" components={{ line: <span /> }} />
  </TooltipCol>
)

const classicOrdersPayload = {
  name: 'Envoi classique',
  preorder: false,
  withCards: false,
  'notClickAndCollect[]': [1, 3],
  stand: false
}

const sellermaniaOrdersPayload = {
  name: 'Envoi Sellermania',
  sellermania: true,
  stand: false
}

function OtherProductsTab({ store, active }: Props) {
  const { t } = useTranslation()
  const { downloadFile, isLoading } = useDownloadFile()
  const [loadingButton, setLoadingButton] = useState<string>('')

  const emit = useEmit()

  const { data: classicOrders, isFetching: classicOrdersLoading } = useQuery(
    ['countOrderPrint', store.id, classicOrdersPayload],
    () => countPurchaseOrders(store.id, classicOrdersPayload),
    {
      enabled: active && store?.warehouse
    }
  )

  const { data: sellermaniaOrders, isFetching: sellermaniaOrdersLoading } = useQuery(
    ['countOrderPrint', store.id, sellermaniaOrdersPayload],
    () => countPurchaseOrders(store.id, sellermaniaOrdersPayload),
    {
      enabled: active && store?.warehouse
    }
  )

  return (
    <>
      {store?.warehouse && (
        <ExportButtonsCardLayout>
          <div>
            <Title2Tooltip title={t('page.order.print.otherProduct.sendedOrders')} text={ShippingTooltip} />

            <PreviewButtonContainer>
              <Button
                onClick={async () => {
                  setLoadingButton('classic')
                  await downloadFile(
                    `${API_ROUTES.downloads.multipleOrders}/${store.id}?${stringify(classicOrdersPayload)}`
                  )

                  emit(EventType.PrintOrder, null)
                }}
                shape="default"
                icon={PrintIcon}
                size="long"
                isLoading={isLoading && loadingButton === 'classic'}
              >
                {t('page.order.print.actions.printClassicShipping')}
              </Button>
              {classicOrdersLoading || !active ? (
                <Loader />
              ) : (
                <PreviewCounterText>
                  {t('page.order.print.details.pendingOrdersCount', { count: classicOrders.totalItems })}
                </PreviewCounterText>
              )}
            </PreviewButtonContainer>
          </div>

          <PreviewButtonContainer>
            <Button
              onClick={async () => {
                setLoadingButton('sellermania')
                await downloadFile(
                  `${API_ROUTES.downloads.multipleOrders}/${store.id}?${stringify(sellermaniaOrdersPayload)}`
                )
                emit(EventType.PrintOrder, null)
              }}
              shape="default"
              icon={PrintIcon}
              size="long"
              isLoading={isLoading && loadingButton === 'sellermania'}
            >
              {t('page.order.print.actions.printSellermania')}
            </Button>
            {sellermaniaOrdersLoading || !active ? (
              <Loader />
            ) : (
              <PreviewCounterText>
                {t('page.order.print.details.pendingOrdersCount', { count: sellermaniaOrders.totalItems })}
              </PreviewCounterText>
            )}
          </PreviewButtonContainer>
        </ExportButtonsCardLayout>
      )}

      <PrintPreorder store={store} active={active} />
      <OtherProductsSearchForm store={store} active={active} />
      <CardLayout>
        <Title2>{t('page.order.print.details.list')}</Title2>
        <PrintList active={active} storeId={store.id} hasCards={false} />
      </CardLayout>
    </>
  )
}

export default OtherProductsTab
