// TODO: To remove when previous form will be discontinued
export enum Shipping {
  Classic = 'Classic',
  BnfClickAndCollect = 'BnfClickAndCollect',
  RivoliClickAndCollect = 'RivoliClickAndCollect',
  ClickAndCollectStore = 'ClickAndCollectStore'
}

export enum OrderType {
  Classic = 'Classic',
  Sellermania = 'Sellermania',
  ClickAndCollectBnf = 'ClickAndCollectBnf',
  ClickAndCollectRivoli = 'ClickAndCollectRivoli',
  ClickAndCollectAnnecy = 'ClickAndCollectAnnecy',
  ClickAndCollectStand = 'ClickAndCollectStand'
}

export type PreoderOptions = {
  preorder?: boolean
  withCards?: boolean | null
  withProducts?: boolean | null
  orderType?: OrderType
}

export enum Languages {
  Fr = 'Fr',
  En = 'En',
  FrEn = 'FrEn',
  All = 'All'
}
