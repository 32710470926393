import { FetchCollaboratorsQueryParams, getCollaboratorsUri, usePaginatedCollaborators } from 'api/collaborators'
import { useOrderSecondaryStatusOptions } from 'hooks/useSelectOptions'
import { OrderProgress } from 'types/enums'
import { OrderSecondaryStatus as PreparationStatus } from 'types/playInApiInterfaces'

export function useCollaboratorsOptions(body: FetchCollaboratorsQueryParams) {
  const {
    data: collaborators,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading
  } = usePaginatedCollaborators(['collaborators', body], getCollaboratorsUri(body))

  const options =
    collaborators?.map((el) => ({
      label: `${el.id} - ${el.lastname} ${el.firstname}`,
      value: el['@id'] as string
    })) ?? []

  return { options, paginationProps: { hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } }
}

export function useCardAssemblingOptions() {
  const { getStatusTraduction } = useOrderSecondaryStatusOptions()
  return {
    options: [
      { label: getStatusTraduction(OrderProgress.CardPreparedComplete), value: PreparationStatus.Complete },
      { label: getStatusTraduction(OrderProgress.CardPreparedIncomplete), value: PreparationStatus.Incomplete },
      { label: getStatusTraduction(OrderProgress.CardPreorder), value: PreparationStatus.Preorder }
    ]
  }
}

export function useCardCheckingOptions() {
  const { getStatusTraduction } = useOrderSecondaryStatusOptions()
  return {
    options: [
      { label: getStatusTraduction(OrderProgress.CardCheckedComplete), value: PreparationStatus.Complete },
      { label: getStatusTraduction(OrderProgress.CardCheckedIncomplete), value: PreparationStatus.Incomplete },
      { label: getStatusTraduction(OrderProgress.CardPreorder), value: PreparationStatus.Preorder },
      { label: getStatusTraduction(OrderProgress.CardSav), value: PreparationStatus.AfterSales }
    ]
  }
}

export function useProductAssemblingOptions() {
  const { getStatusTraduction } = useOrderSecondaryStatusOptions()
  return {
    options: [
      { label: getStatusTraduction(OrderProgress.ProductPreparedComplete), value: PreparationStatus.Complete },
      {
        label: getStatusTraduction(OrderProgress.ProductPreparedIncomplete),
        value: PreparationStatus.Incomplete
      },
      { label: getStatusTraduction(OrderProgress.ProductPreorder), value: PreparationStatus.Preorder }
    ]
  }
}

export function useProductCheckingOptions() {
  const { getStatusTraduction } = useOrderSecondaryStatusOptions()
  return {
    options: [
      { label: getStatusTraduction(OrderProgress.ProductCheckedComplete), value: PreparationStatus.Complete },
      {
        label: getStatusTraduction(OrderProgress.ProductCheckedIncomplete),
        value: PreparationStatus.Incomplete
      },
      { label: getStatusTraduction(OrderProgress.ProductPreorder), value: PreparationStatus.Preorder },
      { label: getStatusTraduction(OrderProgress.ProductSav), value: PreparationStatus.AfterSales }
    ]
  }
}
