import Svg from 'components/Svg/Svg'
import styled from 'styled-components'

export const OrdrePreparationLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;

  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    gap: 0 2rem;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`
export const OrdrePreparationWrapper = styled.div`
  max-width: 100.5rem;
  width: 100%;
`
export const FormLayout = styled.form`
  display: flex;
  gap: 2rem;
  align-items: flex-end;
`

export const StatusRow = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;
`
export const Arrow = styled(Svg)`
  transform: rotate(-90deg);
` as typeof Svg

export const Separator = styled.hr`
  margin: 2rem 0;
  border: 1px solid ${(props) => props.theme.borderColors.secondary};
`
