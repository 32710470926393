import {
  AccessorieFamilyIcon,
  AlteredFamilyIcon,
  BoardgameFamilyIcon,
  DragonballFamilyIcon,
  FigurinesFamilyIcon,
  FleshAndBloodFamilyIcon,
  LegoFamilyIcon,
  LorcanaFamilyIcon,
  MagicFamilyIcon,
  PaintsFamilyIcon,
  PokemonFamilyIcon,
  WarhammerFamilyIcon,
  YugihoFamilyIcon
} from 'constants/familyIcons'
import { CardIcon } from 'constants/icons'
import { useTranslation } from 'react-i18next'
import { FamilyName } from 'types/playInApiInterfaces'

export const useProductFamilies = () => {
  const { t } = useTranslation()

  const getFamilyName = (familyCodeName?: string) => {
    if (!familyCodeName) return undefined

    return {
      [FamilyName.Accessories]: t('common.families.accessories'),
      [FamilyName.Altered]: t('common.families.altered'),
      [FamilyName.Boardgames]: t('common.families.jds'),
      [FamilyName.Cards]: t('common.families.cards'),
      [FamilyName.Dbscg]: t('common.families.dbscg'),
      [FamilyName.Models]: t('common.families.models'),
      [FamilyName.Fftcg]: t('common.families.fftcg'),
      [FamilyName.Fab]: t('common.families.f&b'),
      [FamilyName.Lego]: t('common.families.lego'),
      [FamilyName.Lorcana]: t('common.families.lorcana'),
      [FamilyName.Magic]: t('common.families.magic'),
      [FamilyName.Painting]: t('common.families.painting'),
      [FamilyName.Pokemon]: t('common.families.pokemon'),
      [FamilyName.Catering]: t('common.families.catering'),
      [FamilyName.Service]: t('common.families.service'),
      [FamilyName.W40K]: t('common.families.w40k'),
      [FamilyName.Aos]: t('common.families.aos'),
      [FamilyName.Xwing]: t('common.families.xwing'),
      [FamilyName.Yugioh]: t('common.families.yugioh')
    }[familyCodeName]
  }

  const getFamilyIcon = (familyCodeName?: string) => {
    if (!familyCodeName) return undefined

    return {
      [FamilyName.Accessories]: AccessorieFamilyIcon,
      [FamilyName.Altered]: AlteredFamilyIcon,
      [FamilyName.Boardgames]: BoardgameFamilyIcon,
      // [FamilyName.Cards]:
      [FamilyName.Dbscg]: DragonballFamilyIcon,
      [FamilyName.Models]: FigurinesFamilyIcon,
      // [FamilyName.FinalFantasy]:
      [FamilyName.Fab]: FleshAndBloodFamilyIcon,
      [FamilyName.Lego]: LegoFamilyIcon,
      [FamilyName.Lorcana]: LorcanaFamilyIcon,
      [FamilyName.Magic]: MagicFamilyIcon,
      [FamilyName.Painting]: PaintsFamilyIcon,
      [FamilyName.Pokemon]: PokemonFamilyIcon,
      // [FamilyName.Catering]:
      // [FamilyName.Service]:
      [FamilyName.W40K]: WarhammerFamilyIcon,
      [FamilyName.Aos]: WarhammerFamilyIcon,
      // [FamilyName.XWing]:
      [FamilyName.Yugioh]: YugihoFamilyIcon,
      [FamilyName.Cards]: CardIcon
    }[familyCodeName]
  }

  return { getFamilyName, getFamilyIcon }
}
